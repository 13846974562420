$(function() {
  $('.add-more').on('click', function(){
    let element = $("#"+this.dataset.kind+"-inputs").append($("#"+this.dataset.kind+"-default-input").html());
    element[0].querySelectorAll('.remove-input').forEach(e => {
      e.classList.remove('d-none');
    });
    element[0].querySelectorAll('.new-attorney').forEach(e => {
      e.classList.remove('d-none');
    });
    addRemoveInput();
  });

  function addRemoveInput(){
    $('.remove-input').on('click', function(){
      $(this).parent().parent().parent().remove();
    });
  }

  addRemoveInput();
  $('#kyb-form').parent().parent().addClass('no-border');
});