// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("chart.js")
window.ClipboardJS = require("clipboard")
//= require abraham

// Controller JS
import 'controllers'

import "bootstrap"
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/css/all"
import 'chartjs-plugin-style'

import "./abraham"
import "./advisor-referrals-graph"
import "./asset-rentability-graph"
import "./close-alerts"
import "./forms"
import "./historical-data-performance-graph"
import "./historical-graph"
import "./plan-performance-graph"
import "./purchases-performance-graph"
import "chartkick/chart.js"
import "./sumsub"

// Images
const images = require.context('../images', true)

import legendBlue from 'images/legend_blue.svg'
global.legendBlue = legendBlue
import legendYellow from 'images/legend_yellow.svg'
global.legendYellow = legendYellow
import legendPurple from 'images/legend-purple.svg'
global.legendPurple = legendPurple
import legendLightBlue from 'images/legend-light-blue.svg'
global.legendLightBlue = legendLightBlue

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.addEventListener('load', () => {
  if(navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
  }
});

// Detects if device is on iOS
const isIos = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// Checks if should display install popup notification:
$(function() {
  if (isIos() && !isInStandaloneMode()) {
    $('#prompt').removeClass('d-none')
    setTimeout(function() {
      $("#prompt").fadeOut(3000);
    },4500);
  }
});
