function getData(percentages) {
  var values = Object.values(percentages);

  const data = {
    labels: Object.keys(percentages),
    datasets: [{
      data: values,
      backgroundColor: ['#FFD572BF', '#FF9364BF', '#B09FFFBF', '#99FFA3BF', '#99C8FFBF'],
    }]
  };
  return data;
}

function getOptions() {
  let options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index] + ': ' + data.datasets[0].data[tooltipItem.index] + '%';
        },
        displayColors: false
      },
    },
  }
  return options;
}

window.loadReferralsGraph = async function loadGraph() {
  var ctx = document.getElementById("referrals-chart");
  var percentages = JSON.parse(ctx.dataset.percentages);

  var assetReferralsChart = new Chart(ctx, {
    type: 'doughnut',
    data: getData(percentages),
    options: getOptions()
  })
}
