function getData(profits) {
  var values = Object.values(profits);
  values.forEach( (val, i) => values[i] = Math.min(45, val));

  const data = {
    labels: Object.keys(profits),
    datasets: [{
      data: values,
      backgroundColor: ['#FFD572BF', '#FF9364BF', '#B09FFFBF', '#99FFA3BF', '#99C8FFBF'],
    }]
  };
  return data;
}

function getOptions() {
  let options = {
    legend: {
      display: false,
    },
    rotation: 50,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0
      },
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index];
        },
        displayColors: false
      },
    },
    scale: {
      ticks: {
        callback: function(val, index, tickArray) {
          return val == 0 || val == 40 ? val : '';
        },
        z: 1,
        min: -40,
        max: 45,
        stepSize: 20,
        backdropColor: '#FFFFFF00',
        fontFamily: 'Poppins'
      },
      gridLines: {
        color: ['#ebebeb', '#cbc8c8', '#ebebeb', '#ebebeb', 'transparent'],
        display: true,
      }
    }
  }
  return options;
}

window.loadRentability = async function loadGraph() {
  var ctx = document.getElementById("rentability-chart");
  var profits = JSON.parse(ctx.dataset.profits);

  var assetRentabilityChart = new Chart(ctx, {
    type: 'polarArea',
    data: getData(profits),
    options: getOptions()
  })
}
