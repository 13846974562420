import snsWebSdk from '@sumsub/websdk';


window.launchWebSdk = function initWebSdk(accessToken, lang, applicantEmail, applicantPhone) {
    const config_css = `
        :root {
            --black: #000000;
            --grey: #F5F5F5;
            --grey-darker: #B2B2B2;
            --border-color: #DBDBDB;
        }
        body {
            font-family: 'Roboto', sans-serif;
        }
        p {
            color: var(--black);
            font-size: 16px;
            line-height: 24px;
            letter-spacing: normal;
        }
        h3 {
            font-weight: normal;
        }
        section {
            margin: 40px auto;
        }
        input {
            color: var(--black);
            font-weight: 600;
            outline: none;
        }
        section.content {
            background-color: var(--grey);
            color: var(--black);
            padding: 40px 40px 16px;
            box-shadow: none;
            border-radius: 6px;
        }
        .sumsub-logo {
            display: none !important;
        }
        .iframe2.min-height {
            min-height: 500px;
        }
        .iframe2.min-height iframe {
            min-height: auto;
        }
        .radio-item {
            width: 180px;
        }
        section.content {
            padding: 20px 40px 16px;
        }
    `

    let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            () => this.getNewAccessToken()
        )
        .withConf({
            lang: lang ? lang : 'es',
            email: applicantEmail,
            phone: applicantPhone,
            onMessage: (type, payload) => {
                //console.log('WebSDK onMessage', type, payload)
            },
            uiConf: {
                customCssStr: config_css,
            },
            onError: (error) => {
                //console.error('WebSDK onError', error)
            },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true})
        .on('stepCompleted', (payload) => {
            //console.log('stepCompleted', payload)
        })
        .on('onError', (error) => {
            //console.log('onError', payload)
        })
        .onMessage((type, payload) => {
            //console.log('onMessage', type, payload)
        })
        .build();

    snsWebSdkInstance.launch('.sumsub-websdk-container')
}
