async function evaluate(purchases) {
  let sData = {}
  sData.label = []
  sData.value = []
  sData.purchased = []

  for (let [date, data] of Object.entries(purchases)){
    sData.label.push(new Date(date))
    sData.value.push(data['value'])
    sData.purchased.push(data['amount'])
  }

  return {
    sData
  }
}

function getData(sData) {
  let borderWidth = 4;
  if(window.innerWidth < 992) {
    borderWidth = 2;
  }

  var dataset = [{
    order: 2,
    pointRadius: .7,
    borderWidth: borderWidth,
    borderColor: 'rgb(0, 255, 235)',
    backgroundColor: 'rgb(0, 255, 235, 0.1)', // '#e0dcfc'
    hoverRadius: 10,
    hoverBorderWidth: 2,
    hoverBorderColor: 'rgb(255, 255, 255)',
    hoverBackgroundColor: 'rgb(0, 255, 235)',
    pointHitRadius: 20,
    label: "Valor de portfolio",
    data: sData.value
  },{
    order: 1,
    pointRadius: .7,
    borderWidth: borderWidth,
    borderColor: 'rgb(248, 182, 33)',
    backgroundColor: 'rgba(248, 182, 33, 0.15)', //'#fff4dc'
    hoverRadius: 10,
    hoverBorderWidth: 2,
    hoverBorderColor: 'rgb(255, 255, 255)',
    hoverBackgroundColor: 'rgb(248, 182, 33)',
    pointHitRadius: 20,
    label: "Total invertido",
    data: sData.purchased
  }];

  let data = {
    labels: sData.label,
    datasets: dataset
  };
  return data;
}

function getOptions() {
  let sizeLabel = 10;
  let maxRotation = 0;
  if(window.innerWidth > 1439) {
    sizeLabel = 12;
  }
  if(window.innerWidth < 992) {
    maxRotation = 45;
  }

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0
      }
    },

    legend: {
      display: false,
      align: "end",
      labels: {
        fontSize: 15,
        fontWeight: 400,
        fontColor: "#FFF",
        fontFamily: 'Poppins',
        padding: 20,
        usePointStyle: true,
      }
    },
    legendCallback: function(chart) {
      var text = [];
      for (var i=0; i<chart.data.datasets.length; i++) {
        var img = chart.data.datasets[i].label == "Valor de portfolio" ? legendBlue : legendYellow;
        text.push(
          '<div class="col-auto">',
            '<img class="chart-legend-icon ms-md-5" src=' + img + '>',
            '<span id="legend-' + i + '" class="chart-legend-title ms-1" style="color:' + chart.data.datasets[i].borderColor + '" >' + chart.data.datasets[i].label + '</span>',
          '</div>'
        );
      }
      return text.join("");
    },

    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return '\n\t\t\t\t\t\t\t\t\t\t\t\t' + parseFloat(tooltipItem[0].value).toFixed(2) + ' €\t\t\t\t\t\t\t\t\t\t\t\t';
        },
        label: function(tooltipItem, data) {
          return tooltipItem.label.split(',').slice(0,2).join();
        },
        footer: function(tooltipItem, data) {
          return " ";
        },
      },
      backgroundColor: '#142976',
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowBlur: 8,
      shadowColor: 'rgba(0, 0, 0, 0.35)',
      caretSize: 10,

      titleFontSize: 20,
      titleAlign: 'center',
      titleFontWeight: 100,
      titleFontColor: '#FFF',
      titleFontFamily: "Poppins",
      titleMarginBottom: 12,

      footerMarginTop: 8,

      bodyFontSize: 12,
      bodyAlign: 'center',
      bodyFontWeight: 900,
      bodyFontColor: '#b6b6b6',
      bodyFontFamily: "Poppins",

      displayColors: false
    },

    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'week'
        },
        ticks: {
          beginAtZero: false,
          autoSkip: true,
          autoSkipPadding: 20,
          maxRotation: maxRotation,
          minRotation: 0,
          fontSize: sizeLabel,
          fontFamily: 'Poppins',
          fontWeight: "bold",
          fontColor: "#FFF",
          padding: 10,
          callback: function(value, index, values) {
            return value.toUpperCase();
          }
        },
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          min: 0,
          beginAtZero: false,
          maxTicksLimit: 6,
          fontSize: sizeLabel,
          fontColor: "#FFF",
          fontFamily: 'Poppins',
          padding: 5,
          callback: function(value, index, values) {
            return value + " €";
          }
        },
        gridLines: {
          display: true,
          color: "#FFFFFF",
          zeroLineWidth: 1,
          zeroLineColor: "#FFFFFF",
          drawBorder: false,
        },
        scaleLabel: {
          display: false,
          labelString: "Portfolio Value (USD)",
          fontStyle: "bold",
          fontFamily: 'Poppins',
          fontSize: 13,
        },
      }],
    }
  };
return options;
}

window.loadPerformance = async function loadGraph() {
  var ctx = document.getElementById('dca-chart');
  var purchases = JSON.parse(ctx.dataset.purchases);

  let values = await evaluate(purchases);

  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 20;
  };

  var dcaChart = new Chart(ctx, {
    type: 'line',
    data: getData(values.sData),
    options: getOptions()
  });

  document.getElementById('chart-legend').innerHTML = dcaChart.generateLegend();

  $("#legend-0, #legend-1",).on('click', function() {
    var index = parseInt(this.id.split('-')[1]);
    if (dcaChart.data.datasets[index].hidden==true) {
      this.style.textDecoration = "";
      dcaChart.data.datasets[index].hidden = false;
    } else {
      this.style.textDecoration = "line-through"
      dcaChart.data.datasets[index].hidden = true;
    }
    dcaChart.update();
  });
}
