async function evaluate(prices, dtstart) {
  let sData = {}
  sData.label = []
  sData.price = []

  for (let [date, price] of Object.entries(prices)){
    if (new Date(date) >= new Date(dtstart)){
      sData.label.push(new Date(date).toDateString())
      sData.price.push(price)
    }
  }

  return {
    sData
  }
}

function getData(sData) {

  var dataset = [{
    pointRadius: .7,
    borderWidth: 2,
    borderColor: 'rgb(248, 182, 33)',
    backgroundColor: 'rgba(248, 182, 33, 0.15)', //'#fff4dc'
    hoverRadius: 10,
    hoverBorderWidth: 2,
    hoverBorderColor: 'rgb(255, 255, 255)',
    hoverBackgroundColor: 'rgb(248, 182, 33)',
    pointHitRadius: 3,
    label: "Precio histórico",
    data: sData.price
  }];

  let data = {
    labels: sData.label,
    datasets: dataset
  };

  return data;
}

function getOptions() {
  let sizeLabel = 10;
  let maxRotation = 0;
  if(window.innerWidth > 1439) {
    sizeLabel = 12;
  }
  if(window.innerWidth < 992) {
    maxRotation = 45;
  }

  let options = {
    responsive: true,
    maintainAspectRatio: false,

    legend: {
      display: false,
      align: "end",
      labels: {
        fontSize: 15,
        fontWeight: 400,
        fontColor: "#FFF",
        fontFamily: 'Poppins',
        padding: 20,
        usePointStyle: true,
      }
    },
    legendCallback: function(chart) {
      var text = [];
      for (var i=0; i<chart.data.datasets.length; i++) {
        text.push(
          '<div class="col-auto">',
            '<img src=' + legendYellow + '>',
            '<span id="legend-' + i + '" class="chart-legend-title ms-3" style="color:' + chart.data.datasets[i].borderColor + '" >' + chart.data.datasets[i].label + '</span>',
          '</div>'
        );
      }
      return text.join("");
    },

    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return '\n\t\t\t\t€ ' + parseFloat(tooltipItem[0].value).toFixed(2) + '\t\t\t\t';
        },
        label: function(tooltipItem, data) {
          return '\t\t\t\t\t\t' + tooltipItem.label + '\t\t\t\t\t\t';
        },
        footer: function(tooltipItem, data) {
          return " ";
        },
      },
      backgroundColor: '#142976',
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowBlur: 8,
      shadowColor: 'rgba(0, 0, 0, 0.35)',
      caretSize: 10,

      titleFontSize: 20,
      titleAlign: 'center',
      titleFontWeight: 100,
      titleFontColor: '#FFF',
      titleFontFamily: "Poppins",
      titleMarginBottom: 12,

      footerMarginTop: 8,

      bodyFontSize: 12,
      bodyAlign: 'center',
      bodyFontWeight: 900,
      bodyFontColor: '#b6b6b6',
      bodyFontFamily: "Poppins",

      displayColors: false
    },

    scales: {
      xAxes: [{
        type: 'time',
        time: {
          minUnit: 'day'
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 20,
          maxRotation: maxRotation,
          minRotation: 0,
          fontSize: sizeLabel,
          fontFamily: 'Poppins',
          fontWeight: "bold",
          fontColor: "#FFF",
          padding: 10,
          callback: function(value, index, values) {
            return value.toUpperCase();
          }
        },
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 6,
          fontSize: sizeLabel,
          fontColor: "#FFF",
          fontFamily: 'Poppins',
          padding: 5,
          callback: function(value, index, values) {
            return "€" + value/1000 + 'k';
          }
        },
        gridLines: {
          display: true,
          color: "#FFFFFF",
          zeroLineWidth: 1,
          zeroLineColor: "#FFFFFF",
          drawBorder: false,
        },
        scaleLabel: {
          display: false,
          labelString: "Bitcoin price (USD)",
          fontColor: "#FFF",
          fontStyle: "bold",
          fontFamily: 'Poppins',
          fontSize: 13,
        },
      }],
    }
  };
  return options;
}

window.loadHistorical = async function loadGraph(dtstart) {
  var ctx = document.getElementById('dca-chart');
  var prices = JSON.parse(ctx.dataset.prices);

  let values = await evaluate(prices, dtstart);

  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 20;
  };

  var dcaChart = new Chart(ctx, {
    type: 'line',
    data: getData(values.sData),
    options: getOptions()
  });

  $(".range-selector").on('click', async function() {
    $(".range-selector").removeClass("range-selector--active");
    this.classList.add("range-selector--active");

    var dtstart = this.dataset.dtstart;
    values = await evaluate(prices, dtstart);

    dcaChart.data = getData(values.sData);
    dcaChart.update();
  });

  document.getElementById('chart-legend').innerHTML = dcaChart.generateLegend();

  $("#legend-0, #legend-1").on('click', function() {
    var index = parseInt(this.id.split('-')[1]);
    if (dcaChart.data.datasets[index].hidden==true) {
      this.style.textDecoration = "";
      dcaChart.data.datasets[index].hidden = false;
    } else {
      this.style.textDecoration = "line-through"
      dcaChart.data.datasets[index].hidden = true;
    }
    dcaChart.update();
  });
}
