async function evaluate(purchases) {
  let sData = {}
  sData.label = []
  sData.value = []
  sData.purchased = []

  for (let [date, data] of Object.entries(purchases)){
    sData.label.push(new Date(date))
    sData.value.push(data['value'])
    sData.purchased.push(data['amount'])
  }

  return {
    sData
  }
}

function getData(sData) {
  var dataset = [{
    order: 2,
    pointRadius: .7,
    borderWidth: 3,
    borderColor: '#9857F6',
    backgroundColor: '#9857F64D', // '#e0dcfc'
    hoverRadius: 5,
    hoverBorderWidth: 1,
    hoverBorderColor: 'rgb(255, 255, 255)',
    hoverBackgroundColor: '#9857F6',
    pointHitRadius: 10,
    lineTension: 0.6,
    label: "Valor de portfolio",
    data: sData.value
  },{
    order: 1,
    pointRadius: .7,
    borderWidth: 3,
    borderColor: '#46BBC2',
    backgroundColor: '#46BBC24D', //'#fff4dc'
    hoverRadius: 5,
    hoverBorderWidth: 1,
    hoverBorderColor: 'rgb(255, 255, 255)',
    hoverBackgroundColor: '#46BBC2',
    pointHitRadius: 10,
    lineTension: 0.6,
    label: "Total invertido",
    data: sData.purchased
  }];

  let data = {
    labels: sData.label,
    datasets: dataset
  };
  return data;
}

function getOptions() {
  let options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0
      }
    },


    legend: {
      display: false
    },

    legendCallback: function(chart) {
      var text = [];
      for (var i=0; i<chart.data.datasets.length; i++) {
        var img = chart.data.datasets[i].label == "Valor de portfolio" ? legendPurple : legendLightBlue;
        text.push(
          '<div class="col-auto">',
            '<span id="legend-' + i + '" class="legend-title me-1" style="color:' + chart.data.datasets[i].borderColor + '" >' + chart.data.datasets[i].label + '</span>',
            '<img class="legend-icon me-3" src=' + img + '>',
          '</div>'
        );
      }
      return text.join("");
    },

    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return '\n\t\t\t\t\t\t\t\t\t\t\t\t' + parseFloat(tooltipItem[0].value).toFixed(2) + ' €\t\t\t\t\t\t\t\t\t\t\t\t';
        },
        label: function(tooltipItem, data) {
          return tooltipItem.label.split(',').slice(0,2).join();
        },
        footer: function(tooltipItem, data) {
          return " ";
        },
      },
      backgroundColor: '#F1EEFF',
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowBlur: 8,
      shadowColor: 'rgba(0, 0, 0, 0.35)',
      caretSize: 10,

      titleFontSize: 20,
      titleAlign: 'center',
      titleFontWeight: 100,
      titleFontColor: '#000',
      titleFontFamily: "Poppins",
      titleMarginBottom: 12,

      footerMarginTop: 8,

      bodyFontSize: 12,
      bodyAlign: 'center',
      bodyFontWeight: 900,
      bodyFontColor: '#808080',
      bodyFontFamily: "Poppins",

      displayColors: false
    },

    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'week'
        },
        ticks: {
          beginAtZero: false,
          autoSkip: true,
          autoSkipPadding: 20,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 2,
          fontSize: 12,
          fontFamily: 'Poppins',
          fontWeight: "400",
          fontColor: "#808080",
          padding: 5,
          callback: function(value, index, values) {
            return value.toUpperCase();
          }
        },
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          min: 0,
          beginAtZero: false,
          maxTicksLimit: 6,
          fontSize: 12,
          fontColor: "#808080",
          fontFamily: 'Poppins',
          padding: 5,
          callback: function(value, index, values) {
            return value === 0 ? '' : value;
          }
        },
        gridLines: {
          display: true,
          color: "#FFFFFF",
          zeroLineWidth: 1,
          zeroLineColor: "#FFFFFF",
          drawBorder: false,
        },
        scaleLabel: {
          display: false,
          labelString: "Portfolio Value (USD)",
          fontStyle: "bold",
          fontFamily: 'Poppins',
          fontSize: 13,
        },
      }],
    }
  };
return options;
}

window.drawGraph = async function () {
  var ctx = document.getElementById('performance-chart');
  var purchases = JSON.parse(ctx.dataset.purchases);

  let values = await evaluate(purchases);

  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 20;
  };

  var dcaChart = new Chart(ctx, {
    type: 'line',
    data: getData(values.sData),
    options: getOptions()
  });

  document.getElementById('chart-legend').innerHTML = dcaChart.generateLegend();

  $("#legend-0, #legend-1",).on('click', function() {
    var index = parseInt(this.id.split('-')[1]);
    if (dcaChart.data.datasets[index].hidden==true) {
      this.style.textDecoration = "";
      dcaChart.data.datasets[index].hidden = false;
    } else {
      this.style.textDecoration = "line-through"
      dcaChart.data.datasets[index].hidden = true;
    }
    dcaChart.update();
  });
}
