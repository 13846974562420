async function evaluate(purchases) {
  let sData = {}
  sData.label = []
  sData.datasets = {}
  for (let key of Object.keys(purchases[Object.keys(purchases)[0]])){
    sData.datasets[key] = []
  }

  for (let [date, data] of Object.entries(purchases)){
    sData.label.push(new Date(date))

    for (let dataset in data){
      sData.datasets[dataset].push(data[dataset])
    }
  }

  return {
    sData
  }
}

function getData(sData) {
  let borderWidth = 3;
  if(window.innerWidth < 992) {
    borderWidth = 1;
  }

  var datasets = []
  var colors = ['rgb(248, 182, 33)', 'rgb(207, 90, 90)', 'rgb(202, 140, 60)', 'rgb(99, 146, 60)', 'rgb(60, 143, 146)', 'rgb(179, 49, 179)']

  for (const [plan, dataset] of Object.entries(sData.datasets)){
    var color = colors.pop()
    datasets.push({
      pointRadius: .7,
      borderWidth: borderWidth,
      borderColor: color,
      backgroundColor: 'rgb(0, 0, 0, 0)', // '#e0dcfc'
      hoverRadius: 5,
      hoverBorderWidth: 1,
      hoverBorderColor: color,
      hoverBackgroundColor: color,
      pointHitRadius: 5,
      label: plan,
      data: dataset
    })
  }

  let data = {
    labels: sData.label,
    datasets: datasets
  };
  return data;
}

function getOptions() {
  let sizeLabel = 10;
  let maxRotation = 50;
  if(window.innerWidth > 1439) {
    sizeLabel = 12;
  }
  if(window.innerWidth < 992) {
    maxRotation = 45;
  }

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0
      }
    },

    legend: {
      display: false,
      align: "end",
      labels: {
        fontSize: 15,
        fontWeight: 400,
        fontColor: "#FFF",
        fontFamily: 'Poppins',
        padding: 20,
        usePointStyle: true,
      }
    },
    legendCallback: function(chart) {
      var text = [];
      for (var i=0; i<chart.data.datasets.length; i++) {
        text.push(
          '<div class="col-auto">',
            '<img class="masked ms-md-3" style="background-color:' + chart.data.datasets[i].borderColor + '"></img>',
            '<span id="legend-' + i + '" class="chart-legend-title ms-1" style="color:' + chart.data.datasets[i].borderColor + '" >' + chart.data.datasets[i].label + '</span>',
          '</div>'
        );
      }
      return text.join("");
    },

    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return '\n\t\t\t\t\t\t\t\t\t\t\t\t' + parseFloat(tooltipItem[0].value).toFixed(2) + ' €\t\t\t\t\t\t\t\t\t\t\t\t';
        },
        label: function(tooltipItem, data) {
          return tooltipItem.label.split(',').slice(0,2).join();
        },
        footer: function(tooltipItem, data) {
          return " ";
        },
      },
      backgroundColor: '#142976',
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowBlur: 8,
      shadowColor: 'rgba(0, 0, 0, 0.35)',
      caretSize: 10,

      titleFontSize: 20,
      titleAlign: 'center',
      titleFontWeight: 100,
      titleFontColor: '#FFF',
      titleFontFamily: "Poppins",
      titleMarginBottom: 12,

      footerMarginTop: 8,

      bodyFontSize: 12,
      bodyAlign: 'center',
      bodyFontWeight: 900,
      bodyFontColor: '#b6b6b6',
      bodyFontFamily: "Poppins",

      displayColors: false
    },

    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'week'
        },
        ticks: {
          beginAtZero: false,
          autoSkip: true,
          autoSkipPadding: 20,
          maxRotation: maxRotation,
          minRotation: 0,
          fontSize: sizeLabel,
          fontFamily: 'Poppins',
          fontWeight: "bold",
          fontColor: "#FFF",
          padding: 10,
          callback: function(value, index, values) {
            return value.toUpperCase();
          }
        },
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: false,
          maxTicksLimit: 6,
          fontSize: sizeLabel,
          fontColor: "#FFF",
          fontFamily: 'Poppins',
          padding: 5,
          callback: function(value, index, values) {
            return value + " €";
          }
        },
        gridLines: {
          display: true,
          color: "#acacac",
          zeroLineWidth: 1,
          zeroLineColor: "#acacac",
          drawBorder: false,
        },
        scaleLabel: {
          display: false,
          labelString: "Portfolio Value (USD)",
          fontStyle: "bold",
          fontFamily: 'Poppins',
          fontSize: 13,
        },
      }],
    }
  };
return options;
}

window.loadHistoricalData = async function loadGraph() {
  var ctx = document.getElementById('historical-data-chart');
  var purchases = JSON.parse(ctx.dataset.purchases);

  let values = await evaluate(purchases);


  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 20;
  };

  var dcaChart = new Chart(ctx, {
    type: 'line',
    data: getData(values.sData),
    options: getOptions()
  });

  document.getElementById('chart-legend').innerHTML = dcaChart.generateLegend();

  $("#legend-0, #legend-1, #legend-2, #legend-3, #legend-4",).on('click', function() {
    var index = parseInt(this.id.split('-')[1]);
    if (dcaChart.data.datasets[index].hidden==true) {
      this.style.textDecoration = "";
      dcaChart.data.datasets[index].hidden = false;
    } else {
      this.style.textDecoration = "line-through"
      dcaChart.data.datasets[index].hidden = true;
    }
    dcaChart.update();
  });
}
